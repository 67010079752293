import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllPaymentDetail_Exp,
  //GetAllPaymentDetail_Pay,
  GetAllStores,
  deletePayment,
} from "../../api/Api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Expense = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [r_id, setr_id] = useState("");
  //const [role, setRole] = useState("");
  const { currentColor } = useStateContext();
  const [store, setstore] = useState("");
  const [store_id, setstore_id] = useState("");
  const [getstores, setstores] = useState([]);
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const customerGridImage2 = (props) => <div>{"E" + props.payment_id}</div>;

  const customersGrid =
    // role === "B3" || role === "S3"
    //   ?
    [
      // { headerTemplate: ` `, type: "checkbox", width: "50",  },
      {
        headerText: "ID",
        field: "payment_id",
        template: customerGridImage2,
        minWidth: "90",
        width: "90",
        maxWidth: "100",
        textAlign: "right",
      },

      {
        field: "datetime",
        headerText: "DateTime",
        minWidth: "140",
        width: "140",
        maxWidth: "160",
        textAlign: "Center",
      },

      {
        field: "From",
        headerText: "From",
        minWidth: "180",
        width: "200",
        maxWidth: "320",
        textAlign: "left",
      },
      {
        field: "To",
        headerText: "Expense",
        minWidth: "180",
        width: "200",
        maxWidth: "320",
        textAlign: "left",
      },

      {
        field: "amount_paid",
        headerText: "Amt Paid",
        minWidth: "140",
        width: "140",
        maxWidth: "160",
        format: "C2",
        textAlign: "right",
      },
      {
        field: "note",
        headerText: "Note",
        minWidth: "140",
        width: "160",
        maxWidth: "360",
        textAlign: "left",
      },
      //   ]
      // : [
      //     // { headerTemplate: ` `, type: "checkbox", width: "50",  },
      //     {
      //       headerText: "ID",
      //       field: "payment_id",
      //       template: customerGridImage2,
      //       minWidth: "90",
      //       width: "90",
      //       maxWidth: "100",
      //       textAlign: "right",
      //     },

      //     {
      //       field: "datetime",
      //       headerText: "DateTime",
      //       minWidth: "140",
      //       width: "140",
      //       maxWidth: "160",
      //       textAlign: "Center",
      //     },
      //     {
      //       field: "note",
      //       headerText: "Note",
      //       minWidth: "140",
      //       width: "160",
      //       maxWidth: "360",
      //       textAlign: "left",
      //     },
      //     {
      //       field: "amount_paid",
      //       headerText: "Amount",
      //       minWidth: "140",
      //       width: "140",
      //       maxWidth: "160",
      //       format: "C2",
      //       textAlign: "right",
      //     },
    ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Add new");
      navigate(`/Expense/AddExpense/${store_id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("edit new");
      if (r_id !== "") {
        navigate(`/Expense/EditExpense/${r_id}`);
      } else {
        alert("Please select Expense to edit.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick1 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("delete");
      if (r_id !== "") {
        if (
          window.confirm(`Are you sure you want to delete E${r_id} Expense?`)
        ) {
          const resp = await deletePayment(r_id);
          if (resp.status === 200) {
            navigate("/Expense");
            alert("Expense deleted successfully.");
          } else {
            alert("Expense failed to delete.");
          }
        } else {
        }
      } else {
        alert("Please select Expense to delete.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeStore = (e) => {
    setstore(e.target.value);
    const s_id = getstores.find((item) => item.name === e.target.value);
    setstore_id(s_id.store_id);
    const Sale_Store1 = {
      SaleStore1: e.target.value,
      SaleId: s_id.store_id,
    };
    localStorage.setItem("Sale_Store1", JSON.stringify(Sale_Store1));
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setr_id(selectedRowData.payment_id);
    // console.log(selectedRowData.payment_id);
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      var a = null,
        b = null,
        d = [];
      trackPromise(
        Promise.all([GetAllStores()])
          .then(([resp]) => {
            setstores(resp.data || []);
            d = resp.data || [];
            // setstore(resp.data[0].name);
            // setstore_id(resp.data[0].store_id);
            a = resp.data[0].name;
            b = resp.data[0].store_id;
            //console.log(a, b);
          })
          .catch((err) => {
            console.log(err.message);
          })
          .finally(() => {
            //console.log(d.length);
            if (
              !JSON.parse(localStorage.getItem("Sale_Store1")) ||
              d.length === 1
            ) {
              const Sale_Store1 = {
                SaleStore1: a,
                SaleId: b,
              };
              localStorage.setItem("Sale_Store1", JSON.stringify(Sale_Store1));
            }
            const Sale_Store1 = JSON.parse(localStorage.getItem("Sale_Store1"));
            //console.log(Sale_Store1["SaleStore1"]);

            setstore(Sale_Store1["SaleStore1"]);
            setstore_id(Sale_Store1["SaleId"]);
            setloading(false);
          })
      );
    }
    fetchData();
    // const userDataString = JSON.parse(localStorage.getItem("userData"));
    // setRole(userDataString.role);
    // if (
    //   userDataString.role === "B2" ||
    //   userDataString.role === "B3" ||
    //   userDataString.role === "S2" ||
    //   userDataString.role === "S3"
    // ) {
    //   const resp = GetAllPaymentDetail_Pay();
    //   resp
    //     .then(function (result) {
    //       console.log(result.data);
    //       setAllAccounts(result.data || []);
    //     })
    //     .catch((err) => {
    //       console.log(err.message);
    //     });
    // }
  }, []);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      //console.log(store_id);
      if (
        store_id === "" ||
        store_id === null ||
        store_id === "null" ||
        store_id === undefined ||
        store_id === 0
      ) {
      } else {
        trackPromise(
          Promise.all([GetAllPaymentDetail_Exp(store_id)])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [store_id]);

  const settings = { checkboxMode: "ResetOnRowClick" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Financials" title="EXPENSE" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Add"
              borderRadius="10px"
              onClick={handleAddEmployeesClick}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Update"
              borderRadius="10px"
              onClick={handleEditEmployeesClick}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Delete"
              borderRadius="10px"
              onClick={handleEditEmployeesClick1}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <select
              className="select-custom"
              value={store}
              onChange={handleChangeStore}
            >
              {getstores.map((item) => (
                <option key={item.store_id}>{item.name}</option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            selectionSettings={settings}
            rowHeight={36}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Expense;
