import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllStores,
  GetAllSalesReturnByID,
  GetCustomerList,
} from "../../api/Api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const SalesReturns = () => {
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const [AllProductInStore, setAllProductInStore] = useState("");
  const [sr_id, setsr_id] = useState("");
  const [store, setstore] = useState("");
  const [store_id, setstore_id] = useState("");
  //const [sstore_id, setsstore_id] = useState("");
  const [getstores, setstores] = useState([]);
  const [getcustomers, setcustomers] = useState([]);
  const [customer, setcustomer] = useState("0");
  //const [vendor_id, setvendor_id] = useState("");
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);
  //const sportsData = ["Create Order"];

  // const handleAddEmployeesClick = async (event) => {
  //   TimeoutUtility.resetTimeout();
  //   event.preventDefault();
  //   try {
  //     console.log("Add new");
  //     const path = `/SalesReturn/AddSalesReturn/${store_id}`;
  //     window.open(path, "_blank");
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("edit new");
      if (sr_id !== "") {
        // const so_ids = so_id + "_" + store_id;
        //const so_ids = sr_id;
        const path = `/SalesReturn/EditSalesReturn/${sr_id}`;
        window.open(path, "_blank");
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const InventoryGrid = [
    //{ type: 'checkbox', width: '50' },
    {
      field: "sr",
      headerText: "SR#",
      minWidth: "100",
      width: "100",
      maxWidth: "110",
      textAlign: "right",
    },
    {
      field: "so_id",
      headerText: "SO#",
      minWidth: "100",
      width: "100",
      maxWidth: "110",
      textAlign: "right",
    },
    {
      field: "return_date",
      headerText: "Return Date",
      minWidth: "160",
      width: "160",
      maxWidth: "180",
      textAlign: "left",
    },
    {
      field: "sale_date",
      headerText: "Sale Date",
      minWidth: "160",
      width: "160",
      maxWidth: "180",
      textAlign: "left",
    },
    {
      field: "customer",
      headerText: "Customer",
      minWidth: "140",
      width: "210",
      maxWidth: "280",
      textAlign: "left",
    },
    {
      field: "project_name",
      headerText: "Project Name",
      minWidth: "165",
      width: "170",
      maxWidth: "240",
      textAlign: "left",
    },
    {
      field: "total",
      headerText: "Return Total",
      minWidth: "155",
      width: "160",
      maxWidth: "160",
      textAlign: "right",
    },
    {
      field: "amount_paid",
      headerText: "Amount Paid",
      minWidth: "160",
      width: "160",
      maxWidth: "165",
      textAlign: "right",
    },
    {
      field: "sr_note",
      headerText: "Notes",
      minWidth: "120",
      width: "380",
      maxWidth: "540",
      textAlign: "left",
    },
  ];

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setsr_id(selectedRowData.sr_id);
    //console.log(selectedRowData.so_id);
    //setOrderStatus(selectedRowData.Status);
  };

  const handleChangeStore = (e) => {
    TimeoutUtility.resetTimeout();
    setstore(e.target.value);

    const s_id = getstores.find((item) => item.name === e.target.value);
    // console.log(s_id.store_id);
    setstore_id(s_id.store_id);
    const Sale_Store = {
      SaleStore: e.target.value,
      SaleId: s_id.store_id,
    };
    localStorage.setItem("Sale_Store", JSON.stringify(Sale_Store));
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    try {
      // console.log("Back");
      navigate("/Sales");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChangeVendor = (e) => {
    setcustomer(e.target.value);
    //console.log(e.target.value);
  };

  const handleViewSaleClick = async (event) => {
    TimeoutUtility.resetTimeout();
    // event.preventDefault();
    try {
      //console.log("view sales Return");
      if (sr_id !== "") {
        navigate(`/SalesReturn/ViewSalesReturn/${sr_id}`);
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      let a = null,
        b = null,
        d = [];
      trackPromise(
        Promise.all([GetAllStores()])
          .then(([resp]) => {
            setstores(resp.data || []);
            d = resp.data || [];
            //setstore(resp.data.name);
            a = resp.data[0].name;
            // setstore_id(resp.data[0].store_id);
            b = resp.data[0].store_id;
          })
          .catch((err) => {
            console.log(err.message);
          })
          .finally(() => {
            // console.log(d, JSON.parse(localStorage.getItem("Sale_Store")));
            if (
              !JSON.parse(localStorage.getItem("Sale_Store")) ||
              d.length === 1
            ) {
              const Sale_Store1 = {
                Sale_Store: a,
                SaleId: b,
              };
              //console.log(Sale_Store1);
              localStorage.setItem("Sale_Store", JSON.stringify(Sale_Store1));
            }
            const Sale_Store = JSON.parse(localStorage.getItem("Sale_Store"));
            // console.log(Sale_Store["SaleStore"]);
            setstore_id(Sale_Store["SaleId"]);
            setstore(Sale_Store["Sale_Store"]);
            setloading(false);
          })
      );

      await GetCustomerList()
        .then((resp) => {
          setcustomers(resp.data || []);
          //   setcustomer(resp.data[0].customer);
          setcustomer("0");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (
        store_id === "" ||
        store_id === null ||
        store_id === "null" ||
        store_id === undefined ||
        store_id === 0
      ) {
      } else {
        var vv = customer;
        if (customer === "0" || customer === null || customer === 0) {
          vv = null;
        }
        trackPromise(
          Promise.all([GetAllSalesReturnByID(store_id, vv)])
            .then((result) => {
              //console.log(result);
              setAllProductInStore(result[0].data);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [store_id, customer]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Orders" title="SALES RETURNS" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {/* <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Add"
              borderRadius="10px"
              onClick={handleAddEmployeesClick}
            />
          </Col> */}
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Update"
              borderRadius="10px"
              onClick={handleEditEmployeesClick}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <select
              className="select-custom"
              value={customer}
              onChange={handleChangeVendor}
            >
              <option value={0}>{"Select Customer"}</option>
              {getcustomers.map((item) => (
                <option key={item.customer_id} value={item.customer_id}>
                  {item.customer}
                </option>
              ))}
            </select>
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <select
              className="select-custom"
              value={store}
              onChange={handleChangeStore}
            >
              {getstores.map((item) => (
                <option key={item.store_id}>{item.name}</option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <GridComponent
          className="custom-grid"
          dataSource={AllProductInStore}
          recordDoubleClick={handleViewSaleClick}
          allowPaging={true}
          pageSettings={{ pageSize: 16 }}
          allowSorting
          allowResizing
          toolbar={["Search"]}
          rowSelected={handleRowSelected}
          rowHeight={36}
        >
          <ColumnsDirective>
            {InventoryGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
          />
        </GridComponent>
      )}
      <div className="flex justify-center">
        <Button
          margin="7px"
          color="white"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </div>
    </div>
  );
};

export default SalesReturns;
